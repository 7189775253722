export const Users = {
  users: '/users',
  editUser: '/users/edit/:id',
};

export const Groups = {
  groups: '/groups',
  editGroup: '/groups/edit/:id',
  createGroup: '/groups/new-group',
};

export const Reports = {
  reports: '/reports',
  viewPost: '/reports/:id/post/:postId',
  viewGroup: '/reports/:id/group/:groupId',
};

export const Messages = {
  messages: '/messages',
};

export const Management = {
  manangement: '/management',
  profile: '/management/profile',
  editProfile: '/management/profile/edit/:id',
};

export const CommonRoutes = {
  logout: '/logout',
};

export const ErrorRoutes = {
  error403: '/403',
  error404: '/404',
};

export const TransportationQuote = {
  quotes: '/transportationQuote'
};

const Routes = {
  Users: Users,
  Groups: Groups,
  Reports: Reports,
  Management: Management,
  Common: CommonRoutes,
  Errors: ErrorRoutes,
  Messages: Messages,
  TransportationQuote: TransportationQuote
};

export default Routes;

import { createSlice } from '@reduxjs/toolkit';
import quotesApi from '../api/quotes-api';
import { getId } from '../../../../common/utils/utils';
const initialState = {
    quotes: [],
    metadata: { total: 0, page: 1 },
    loading: false,
    error: null,
};

const _loadStart = (state, action) => {
    state.loading = true;
    state.error = null;
};

const _loadFailed = (state, action) => {
    const { error } = action.payload;
    state.loading = false;
    state.error = error;
};

const _loadQuotesSuccess = (state, action) => {
    const { quotes, metadata } = action.payload;
    state.loading = false;
    state.quotes = quotes;
    state.metadata = metadata;
};

const _cleanQuotes = (state) => {
    state = initialState;
};



const quotes = createSlice({
    name: 'quotes',
    initialState: initialState,
    reducers: {
        loadStart: _loadStart,
        loadFailed: _loadFailed,
        loadQuotesSuccess: _loadQuotesSuccess,
        cleanQuotes: _cleanQuotes,
    },
});

const { actions, reducer } = quotes;

export const {
    loadStart,
    loadFailed,
    loadQuotesSuccess,
    cleanQuotes
} = actions;

export default reducer;


export const quotesSelector = (state) => state.quotes;


export const loadQuotes = (page = 1, limit = 6, searchValue = '') => {
    return async (dispatch) => {
        dispatch(loadStart());
        const { status, data, error } = await quotesApi.fetchQuotes(page, limit, getId(), searchValue);
        if (status === 200) {
            return dispatch(loadQuotesSuccess(data));
        } else {
            return dispatch(loadFailed(error));
        }
    };
};

import React, { useEffect } from 'react';
import { PageSection } from '../../../components/PageLayout/PageSection';
import { FlexColumn } from '../../../common/components/ui/CommonStyledComponent';
import GeneralTable from '../../../components/tables/GeneralTable';
import columns from './QuoteTableColumns';
import { useDispatch, useSelector } from 'react-redux';
import { loadQuotes } from '../store/reducers/quote-reducer';
const limit = 10;

const TransportationQuote = () => {
    const { quotes, loading, metadata } = useSelector((state) => state.quotes);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(loadQuotes(1, limit));
    }, []);

    const changePage = (page) => {
        dispatch(loadQuotes(page, limit));
    };
    return (
        <PageSection style={{ width: '100%', height: 'calc(100% - 65px)', boxSizing: 'border-box' }}>
            <FlexColumn style={{ padding: '20px', position: 'relative' }}>
                <GeneralTable
                    loading={loading}
                    data={quotes}
                    columns={columns}
                    limit={limit}
                    pages={Math.ceil(metadata.total / limit)}
                    page={metadata.page}
                    onPageChange={changePage}
                />
            </FlexColumn>
        </PageSection>
    )
}

export default TransportationQuote
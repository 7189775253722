import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';
import { useSelector, useDispatch } from 'react-redux';
import * as _ from 'lodash';

import { PageSection } from '../../PageLayout/PageSection';
import { FlexRow, FlexColumn, Separator, FlexColumnCentered } from '../../../common/components/ui/CommonStyledComponent';
import { imagePath, fullName, getId } from '../../../common/utils/utils';
import { PageContainer } from '../ManagementStyles';
import RoundProfileImage from '../../../common/components/ui/RoundComponents';
import TitledInput from '../../../common/components/ui/inputs/TitledInput';
import { MButtonBlue } from '../../../common/components/ui/Buttons';
import ImageUploadPopup from '../../../common/components/ui/ImageUploadPopup';
import { FloatingSpinner } from '../../../common/components/ui/LoadingComponent';
import { loadProfile, cleanProfile, updateProfile, updateUserAvatar } from '../../../store/actions/management/profile/profile-actions';
import { changePassword } from '../../../store/actions/management/profile/password-change-actions';
import { getImagePresignedUrl, deleteImage } from '../../../store/actions/files/files-actions';
import { reloadUserInfo } from '../../../store/actions/auth/auth-actions';

const Profile = () => {
	const { profile, loading, error } = useSelector(state => state.management.profile);
	const passwordState = useSelector(state => state.management.changePassword);
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const { addToast } = useToasts();

	const [showImageUploadPopup, setShowImageUploadPopup] = useState(false);
	const [viewProfile, setViewProfile] = useState(null);
	const [passowrds, setPasswords] = useState({ oldPassword: '', newPassword: '', confirmPassword: '' });

	useEffect(() => {
		dispatch(loadProfile(getId()));

		return () => {
			dispatch(cleanProfile());
		}
	}, []);

	useEffect(() => {
		setViewProfile(profile);
	}, [profile]);

	useEffect(() => {
		if (error) {
			addToast(error, { appearance: 'error', autoDismiss: true });
		}
	}, [error]);

	useEffect(() => {
		if (passwordState.error) {
			addToast(passwordState.error, { appearance: 'error', autoDismiss: true });
		}
	}, [passwordState.error]);

	const onImageUploadDone = (fileName) => {
		const oldAvatar = viewProfile.avatar;
		dispatch(updateUserAvatar(fileName, getId())).then(data => {
			if (!data.error) {
				addToast(t('saved.label'), { appearance: 'success', autoDismiss: true });
				if (oldAvatar) {
					dispatch(deleteImage(oldAvatar, getId()));
				}
				dispatch(reloadUserInfo());
			}
			else {
				addToast(t('image-update-error.label'), { appearance: 'error', autoDismiss: true });
				if (fileName) {
					dispatch(deleteImage(fileName, getId()));
				}
			}
		})
	}

	const isDataValid = () => {
		return viewProfile.firstName &&
			viewProfile.firstName.replace(/[ ]+/, '').length > 0 &&
			viewProfile.lastName &&
			viewProfile.lastName.replace(/[ ]+/, '').length > 0;
	}

	const onSave = () => {
		dispatch(updateProfile(viewProfile, getId())).then((data) => {
			if (!data.error) {
				addToast(t('saved.label'), { appearance: 'success', autoDismiss: true });
				dispatch(reloadUserInfo());
			}
		});
	}

	const onChange = () => {
		dispatch(changePassword(passowrds, getId())).then(data => {
			setPasswords({ oldPassword: '', newPassword: '', confirmPassword: '' })
			if (!data.error) {
				addToast(t('changed.label'), { appearance: 'success', autoDismiss: true });
			}
		})
	}

	return (
		<PageSection>
			<FloatingSpinner loading={loading || passwordState.loading} size="2x" />
			<PageContainer>
				{viewProfile ?
					<FlexColumn style={{ maxWidth: '650px', alignSelf: 'center', width: '100%', paddingBottom: '40px' }}>
						<FlexRow>
							<h2>{t('profile.label')}</h2>
						</FlexRow>
						<FlexRow>
							<RoundProfileImage
								width={140}
								height={140}
								fontSize={'40px'}
								imgUrl={imagePath(_.get(viewProfile, 'avatar', undefined))}
								name={fullName(viewProfile)}
								withButton={true}
								withDeleteButton={true}
								deleteClickHandler={() => onImageUploadDone('')}
								changeClickedHandler={() => setShowImageUploadPopup(true)} />
							<FlexColumn style={{ flex: 1, paddingLeft: '60px' }}>
								<TitledInput title={t('first-name.label')}
									onChange={(e) => setViewProfile({ ...viewProfile, firstName: e.target.value })}
									value={viewProfile.firstName}></TitledInput>
								<TitledInput title={t('last-name.label')}
									onChange={(e) => setViewProfile({ ...viewProfile, lastName: e.target.value })}
									value={viewProfile.lastName}></TitledInput>
							</FlexColumn>
						</FlexRow>
						<FlexColumnCentered style={{ paddingBottom: '10px' }}>
							<MButtonBlue style={{ maxWidth: '200px' }} onClick={onSave} disabled={loading || !isDataValid()}>{t('save.label')}</MButtonBlue>
						</FlexColumnCentered>
						<Separator />
						<FlexColumn style={{ paddingTop: '20px' }}>
							<TitledInput title={t('old-passwrod.label')}
								type="password"
								onChange={(e) => { setPasswords({ ...passowrds, oldPassword: e.target.value }) }}
								value={passowrds.oldPassword}></TitledInput>
							<TitledInput title={t('new-password.label')}
								type="password"
								onChange={(e) => { setPasswords({ ...passowrds, newPassword: e.target.value }) }}
								value={passowrds.newPassword}></TitledInput>
							<TitledInput title={t('confirm-password.label')}
								type="password"
								onChange={(e) => { setPasswords({ ...passowrds, confirmPassword: e.target.value }) }}
								value={passowrds.confirmPassword}></TitledInput>
						</FlexColumn>
						<FlexColumnCentered style={{ paddingBottom: '10px' }}>
							<MButtonBlue style={{ maxWidth: '200px' }}
								onClick={onChange}
								disabled={!passowrds.oldPassword ||
									!passowrds.newPassword ||
									!passowrds.confirmPassword ||
									passowrds.newPassword !== passowrds.confirmPassword || passwordState.loading}>{t('change.label')}</MButtonBlue>
						</FlexColumnCentered>
						<Separator />
					</FlexColumn>
					: null}
			</PageContainer>
			{showImageUploadPopup ?
				<ImageUploadPopup
					closeAction={() => setShowImageUploadPopup(false)}
					onUploadDone={onImageUploadDone}
					getPresignedUrlAction={(fileName, requestId) => dispatch(getImagePresignedUrl(fileName, requestId))}
					deleteImage={(fileName, requestId) => dispatch(deleteImage(fileName, requestId))} />
				: null}
		</PageSection>
	);
};

export default Profile;
import QuotesRoute from "./routes/index";
import axiosInstance from '../../../../axios';

const fetchQuotes = async (page, limit, requestId) => {
    try {
        const headers = {
            'f-page': page,
            'f-limit': limit,
            requestId: requestId,
        };

        let url = QuotesRoute.quotes;
        let url2 = QuotesRoute.quotes + '/totalquotes';

        const { status, data, error } = await axiosInstance.get(url, { headers: headers });
        if (data) {
            const { status: status2, data: data2, error: error2 } = await axiosInstance.get(url2, { headers: headers });
            let payload = { quotes: data.getTransportationQuotes, metadata: { total: data2.totalQuotes, page: page } };
            return { data: payload, status: status2 ? status2 : status, error: error2 ? error2 : error };
        }
    } catch (error) {
        console.log(error)
    }
}

const quotesApi = {
    fetchQuotes
}

export default quotesApi
import React from 'react';
import RoundProfileImage from '../../../common/components/ui/RoundComponents';
import { formatDate } from '../../../common/utils/date-utils';
import { imagePath } from '../../../common/utils/utils';


const columns = [
    {
        id: 'name',
        Header: 'User Name',
        accessor: row => (row.userInfo && row.userInfo.fullName) ? row.userInfo.fullName : 'N/A',
        disableSortBy: true,
        width: '10%',
    },
    {
        id: 'phone',
        Header: 'Phone',
        accessor: row => (row.userInfo && row.userInfo.phone) ? row.userInfo.phone : 'N/A',
        disableSortBy: true,
        width: '10%',
    },
    {
        id: 'email',
        Header: 'Email',
        accessor: row => (row.userInfo && row.userInfo.email) ? row.userInfo.email : 'N/A',
        disableSortBy: true,
        width: '10%',
    },
    {
        id: 'contactConfirmation',
        Header: 'Contact Confirmation',
        accessor: row => row.contactConfirmation ? "Yes" : 'No',
        disableSortBy: true,
        width: '10%',
    },
    {
        id: 'post',
        Header: 'Post',
        accessor: row => (row.postInfo && row.postInfo.images) ? row.postInfo.images[0] : 'N/A',
        Cell: (props) => {
            if (props.value) {
                return <RoundProfileImage radius={5} width={100} height={100} imgUrl={imagePath(props.value)} withButton={false} withDeleteButton={false} />;
            } else {
                return null;
            }
        },
        disableSortBy: true,
        width: '10%',
    },
    {
        id: 'createddate',
        Header: 'Date',
        accessor: 'createdAt',
        Cell: (props) => {
            if (props.value) {
                return formatDate(props.value);
            } else {
                return null;
            }
        },
        disableSortBy: true,
        width: '10%',
    },
]

export default columns;

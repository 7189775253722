import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Routes from '../routes';
import Users from '../../features/usersManagement/components/Users';
import Groups from '../../features/groups/components/Groups';
import NewGroup from '../../features/groups/components/NewGroup';
import Reports from '../../features/Reports/components/Reports';
import ReportedPost from '../../features/Reports/components/ReportedPost';
import ReportedGroup from '../../features/Reports/components/ReportedGroup';
import Logout from '../Logout/Logout';
import Management from '../Management/Management';
import EditProfile from '../../features/usersManagement/components/EditProfile';
import Messages from '../../features/messages/components/Messages';
import TransportationQuote from '../../features/transportationQuote/Component/TransportationQuote';

const NavigationSwitch = () => {
  return (
    <Switch>
      <Route path={Routes.Common.logout} component={Logout} exact></Route>
      <Route path={Routes.Users.users} component={Users} exact></Route>
      <Route path={Routes.Users.editUser} component={EditProfile} exact></Route>
      <Route path={Routes.Groups.groups} component={Groups} exact></Route>
      <Route path={Routes.TransportationQuote.quotes} component={TransportationQuote} exact></Route>
      <Route path={Routes.Groups.createGroup} component={NewGroup} exact></Route>
      <Route path={Routes.Reports.reports} component={Reports} exact></Route>
      <Route path={Routes.Reports.viewPost} component={ReportedPost} exact></Route>
      <Route path={Routes.Reports.viewGroup} component={ReportedGroup} exact></Route>
      <Route path={Routes.Messages.messages} component={Messages} exact></Route>
      <Route path={Routes.Management.manangement} component={Management}></Route>
      <Redirect from='/' to={Routes.Users.users} />
    </Switch>
  );
};

export default NavigationSwitch;
